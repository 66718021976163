"use client";

import type { Metadata } from "next";

import { ErrorView } from "@/views/ErrorView";

export const metadata: Metadata = {
  robots: {
    index: false,
    follow: false,
  },
};

export default ErrorView;
